import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React, { useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';

import strings from '../configs/strings';


import {
  useAuth,
  useUser
} from "@clerk/clerk-react";

const environment = process.env.REACT_APP_ENVIRONMENT; //prod or dev

let MAIN_API_NAME = strings.MAIN_API_NAME
// let PROMPT_PARAM_NAME = "prompt"

// for this app - could give template line images for users to use

let MAIN_API_URL = strings.MAIN_API_URL

function UserEmailAddress() {
  const { isLoaded, isSignedIn, user } = useUser();

  if (!isLoaded || !isSignedIn) {
    return "";{/*null;*/}
  }
  return user.primaryEmailAddress.emailAddress;
}

function FxInputForm3() {
    const [imageURL1, setImageURL1] = useState(null);
    const [imageURL2, setImageURL2] = useState(null);

    const [videoURL1, setVideoURL1] = useState(null);
    const [videoURL2, setVideoURL2] = useState(null);

    const [audioURL1, setAudioURL1] = useState(null);
    const [audioURL2, setAudioURL2] = useState(null);

    const [selectedFile1, setSelectedFile1] = useState(null);
  
    const handleFileChange1 = (event) => {
      let outf = event.target.files[0];
      if (outf != null) {
        setSelectedFile1(outf);
        // setImageURL1(URL.createObjectURL(outf));
        setVideoURL1(URL.createObjectURL(outf));
        updateVideo1();
      }
      else 
      {
        setSelectedFile1(null);
        // setImageURL1(null);
        setVideoURL1(null);
      }
    };


    const [selectedFile2, setSelectedFile2] = useState(null);
  
    const handleFileChange2 = (event) => {
      let outf = event.target.files[0];
      if (outf != null) {
        setSelectedFile2(outf);
        setAudioURL1(URL.createObjectURL(outf));
        updateAudio1();
      }
      else 
      {
        setSelectedFile2(null);
        setAudioURL1(null);
      }
    };

    
  
    //const authenticatedFetch = useFetch();
    const { getToken } = useAuth();
    const emailAddr = UserEmailAddress();

    const [textOutput, setTextOutput] = useState('');

    const inputProps = {
      inputProps: {
        style: { textAlign: 'center' },
      },
    };
  
    const handleTextOutputChange = event => {
        {/*console.log('Saving value');
    console.log(event.target.value);*/}
        setTextOutput(event.target.value);
    };


    const [textInput1, setTextInput1] = useState('');
    const handleTextInputChange1 = event => {
        {/*console.log('Saving value');
    console.log(event.target.value);*/}
        setTextInput1(event.target.value);
    };

    const [textInput2, setTextInput2] = useState('');
    const handleTextInputChange2 = event => {
        {/*console.log('Saving value');
    console.log(event.target.value);*/}
        setTextInput2(event.target.value);
    };

    const audioRef1 = useRef();
    const audioRef2 = useRef();
    const updateAudio1 = () => { // reload the song, important or the current song won't change
      if(audioRef1.current){
        audioRef1.current.pause();
        audioRef1.current.load();
      }
    }
    const updateAudio2 = () => { // reload the song, important or the current song won't change
      if(audioRef2.current){
        audioRef2.current.pause();
        audioRef2.current.load();
      }
    }

    const videoRef1 = useRef();
    const videoRef2 = useRef();
    const updateVideo1 = () => { // reload the video, important or the current video won't change
      if(videoRef1.current){
        videoRef1.current.pause();
        videoRef1.current.load();
      }
    }
    const updateVideo2 = () => { // reload the video, important or the current video won't change
      if(videoRef2.current){
        videoRef2.current.pause();
        videoRef2.current.load();
      }
    }

    let audioElement;
    let successMessage = ""
    if (textOutput == "")
    {
      audioElement = <p></p>;
    }
    else if (isValidHttpUrl(textOutput))
    {
      successMessage = <p>{"Success!!! Output below!"}</p>;
      audioElement = 
        <audio controls ref={audioRef2}>
          <source src={textOutput} type="audio/mpeg"></source>
        </audio>;
    }
    else {
      /* console.log("greet2 $textOutput"); */
      audioElement = <p>{textOutput}</p>;
    }



    let videoElement;
    if (textOutput == "")
    {
      videoElement = <p></p>;
    }
    else if (isValidHttpUrl(textOutput))
    {
      videoElement = 
        <video controls ref={videoRef2}>
          <source src={textOutput} type="video/mp4"></source>
        </video>;
    }
    else {
      /* console.log("greet2 $textOutput"); */
      videoElement = <p>{textOutput}</p>;
    }
  

  
    const inputProps1 = {
      inputProps: {
        style: { textAlign: 'center' },
      },
    };
  
    function scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // You can use 'auto' instead of 'smooth' for instant scrolling
      });
    }
    
    function handleUpload() {
      // scroll to top
      scrollToTop()

      // Implement upload logic here, e.g., using Fetch API or a library like Axios
      setTextOutput("Loading...");

      const formData = new FormData();
      // formData.append('video1', selectedFile1);
      formData.append('sound1', selectedFile1);
      formData.append('email', emailAddr);
      formData.append('env', environment);
      formData.append('api_name',MAIN_API_NAME)

      formData.append('prompt1',textInput1);
      // formData.append('chords',textInput2);

      // updateSong after receiving response
      
      // console.log(selectedFile);

      const fetchX = async () => {
        const myToken = await getToken();
        // Make an API request to upload the file
        console.log(MAIN_API_URL);
        fetch(MAIN_API_URL, {
          mode: 'cors',
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${myToken}`,
          },
          body: formData,
        })
        .then(response => response.json())
        .then(response => {
          // scroll to top
          scrollToTop()

          // Handle response from server
          console.log(JSON.stringify(response["message"]));
          setTextOutput(response["message"]);
        })
        .catch(error => {
          // scroll to top
          scrollToTop()

          // Handle error
          setTextOutput("Sorry, action failed");//error.message);
          console.error(error);
          //console.log("error")
        });
      };
      fetchX();
    }

    const myaspectstyle = {
      width: "100%",
      height: "100%"
    }; {/*aim is to preserve aspect ratio of image*/}

    let imgElement;
    let outputDisplayText;

    let inputDisplayText = <h2>Input</h2>;

    if (textOutput == "")
    {
      imgElement = <p></p>;
      outputDisplayText = <p></p>;
    }
    else if (isValidHttpUrl(textOutput))
    {
      imgElement = <img src={textOutput} alt="a url 1"  style={myaspectstyle}></img>;
      outputDisplayText = <h2>Output</h2>;
    }
    else {
      /* console.log("greet2 $textOutput"); */
      imgElement = <p>{textOutput}</p>
      outputDisplayText = <h2>Output</h2>;
    }


    let imgElement1;
    if (imageURL1 && imageURL1.length > 2)
    {
      
      imgElement1 = <img src={imageURL1} alt="a url 1"  style={myaspectstyle}></img>
    }
    else {
      imgElement1 = <p></p>
    } 

    let vidElement1;
    if (videoURL1 && videoURL1.length > 2)
    {
      
      vidElement1 = <video controls ref={videoRef1}>
                      <source src={videoURL1} type="video/mp4"></source>
                    </video>;
    }
    else {
      vidElement1 = <p></p>
    } 

    let audElement1;
    if (audioURL1 && audioURL1.length > 2)
    {
      
      audElement1 = <audio controls ref={audioRef1}>
                      <source src={audioURL1} type="audio/mpeg"></source>
                    </audio>;
    }
    else {
      audElement1 = <p></p>
    } 

    let outputArea = (
    <div>
    <Box display="flex" 
         alignItems="center" 
         bgcolor="white"
         marginTop={0} 
         marginBottom={0}
         justifyContent="center">
      {outputDisplayText}
    </Box>
    <Box display="flex" 
         alignItems="center" 
         bgcolor="white"
         marginTop={0} 
         marginBottom={5}
         marginLeft={0} 
         marginRight={0} 
         justifyContent="center">
      {audioElement}
    </Box>
    <hr />
    </ div>);
  
    if (textOutput == "")
    {
      outputArea = <div></div>
    }

    return (
      <div>
        {outputArea}
        <Box display="flex" 
             alignItems="center" 
             bgcolor="white"
             marginTop={0} 
             marginBottom={0}
             justifyContent="center">
          {inputDisplayText}
        </Box>
        <Box display="flex" 
             marginTop={2} 
             alignItems="center" 
             bgcolor="white"
             justifyContent="center">
        <p>Choose a source audio</p>
        </Box>
        <Box display="flex" 
             marginTop={2} 
             alignItems="center" 
             bgcolor="white"
             justifyContent="center">
        <input type="file" accept="audio/*" onChange={handleFileChange1} />
        <br />
        </Box>
        <Box display="flex" 
             marginTop={5} 
             alignItems="center" 
             bgcolor="white"
             justifyContent="center">
        <TextField 
          id="outlined-basic" 
          label="Prompt...." 
          variant="outlined" 
          InputProps={inputProps}
          value={textInput1}
          multiline
          onChange={handleTextInputChange1}/>
        </Box>
        <Box display="flex" 
             marginTop={5} 
             marginBottom={5} 
             alignItems="center" 
             bgcolor="white"
             justifyContent="center">
        <Button variant="contained"
                onClick={handleUpload}>Submit Input</Button>
        </ Box>
        <Box display="flex" 
             marginTop={2} 
             marginBottom={8} 
             marginLeft={0} 
             marginRight={0} 
             alignItems="center" 
             bgcolor="white"
             justifyContent="center"
             objectFit="contain">
          {audElement1}
        </Box>
      </div>
    );
  }

  function isValidHttpUrl(string) {
    let url;
    
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
  
    return url.protocol === "http:" || url.protocol === "https:";
  }

  export default FxInputForm3;